/* Reset and base styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fade-in {
  opacity: 1 !important;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.6;
  color: #1d1d1f;
  background-color: #ffffff;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  margin-bottom: 1rem;
}

a {
  color: #373737;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Header styles */
header {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 15px 0;
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header h1 {
  font-size: 24px;
  font-weight: 400;
}

/* Navigation styles */
nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  margin-left: 40px;
}

nav ul li a {
  color: #1d1d1f;
  font-size: 16px;
}

/* Main content area */
main {
  flex-grow: 1;
  padding: 60px 0;
}

/* Footer styles */
footer {
  background-color: #f5f5f7;
  color: #86868b;
  font-size: 14px;
  padding: 40px 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  margin-bottom: 30px;
  min-width: 200px;
}

.footer-section h4 {
  color: #1d1d1f;
  font-size: 18px;
  margin-bottom: 1rem;
}

.footer-section ul {
  list-style: none;
}

.footer-section ul li {
  margin-bottom: 0.8em;
}

.footer-section a {
  color: #515154;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-bottom {
  border-top: 1px solid #d2d2d7;
  margin-top: 30px;
  padding-top: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-section {
    margin-bottom: 40px;
  }
}

/* Typography */
h1 {
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: -0.005em;
}

h2 {
  font-size: 36px;
  line-height: 1.3;
  letter-spacing: -0.003em;
}

h3 {
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: 0em;
}

h4 {
  font-size: 24px;
  line-height: 1.4;
}

h5 {
  font-size: 20px;
  line-height: 1.5;
}

h6 {
  font-size: 18px;
  line-height: 1.5;
}

p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Button styles */
.button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #0071e3;
  color: #ffffff;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  transition: all 0.3s ease;
  margin: 10px 0;
}

.button:hover {
  background-color: #0077ed;
  text-decoration: none;
}

/* Responsive design */
@media (max-width: 768px) {
  .container {
    padding: 0 20px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }
}

/* Card styles */
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 30px 0;
}

.card {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 30px;
  flex: 1 1 300px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.card h3 {
  margin-top: 0;
  color: #333;
  font-size: 24px;
  margin-bottom: 15px;
}

.card p {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}
